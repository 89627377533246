<div>
  <div class="container">
    <div class="row">
      <!-- Search Bar -->
      <div class="col-md-3 search-bar mt-3">
        <img class="col-md-2 icon" src="\assets\svg\search_bar.svg" alt="search" />
        <input type="search" class="search-input col-md-10" placeholder="Search"
          (input)="onSearchInput($event.target.value)" />
      </div>
  
      <!-- Department Filter -->
      <div class="col-md-3 mt-3">
        <select class="form-control" (change)="onDepartmentChange($event.target.value)">
          <option *ngFor="let dept of departments" [value]="dept.value">{{ dept.label }}</option>
        </select>
      </div>
  
      <!-- Bulk Import -->
      <div class="col-md-1 btn mt-3">
        <div class="row">
          <div class="col-md-6 centered-content" [matTooltipPosition]="position.value"
            (click)="addBulkAttendanceRecords()" [matTooltipClass]="position.value" matTooltip="Bulk Import">
            <img class="img-class-bulk" src="assets/svg/bulk-import.svg" />
          </div>
        </div>
      </div>

       <!-- Date Picker -->
       <div class="col-md-2 from-date mt-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label class="common-heading">Date <span class="contentRed"></span></mat-label>
          <input matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy" autocomplete="off"
            [(ngModel)]="selectedDate" (dateChange)="onDateChange($event)" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <pagination-controls 
  class="pagination-controls mt-3"
  (pageChange)="onPageChange($event)"
  previousLabel="<" nextLabel=">">
</pagination-controls>
  
     
    </div>
  </div>
  
  <div *ngIf="!isSpinnerActive" class="container">
    <div class="mt-3 row">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th colspan="2">
                <div class="table-header-cell">
                  <span class="name">Name</span>
                </div>
              </th>
              <th>
                <div class="table-header-cell">
                  <span class="name">Hours</span>
                </div>
              </th>
              <th>
                <div class="table-header-cell">
                  <span class="name">Monthly Hours</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let attendance of filteredAttendances| paginate : { itemsPerPage: this.pageSize, totalItems: this.totalPages * pageSize, currentPage: page }">
              <td colspan="2">
                <div class="row">
                  <label class="data">{{ attendance.firstName }} {{ attendance.lastName }}</label>
                </div>
              </td>
              <td>
                <label class="data">
                  {{ formatMinutes(attendance.totalWorkedMinutes) }}
                </label>
              </td>
              <td>
                <label class="data">
                  {{ formatMinutes(attendance.totalWorkedMinutesForMonth) }}
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


  </div>
  <div *ngIf="isSpinnerActive" class="spinner-div">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <label class="loading_label">Loading...</label>
  </div>
  <div *ngIf="!isSpinnerActive && attendances.length===0" class="spinner-div">
    <label class="loading_label">No data available for the date: {{selectedDate | date}}</label>
  </div>
  <div *ngIf="!isSpinnerActive && (filteredAttendances.length===0 && !(attendances.length===0))" class="spinner-div">
    <label class="loading_label">No matching records found for given filters, try searching something else...</label>
  </div>

</div>