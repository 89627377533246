import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { OfficeAttendanceService } from 'src/app/services/office-attendance/office-attendance.service';
import { OfficeAttendanceImportDialogComponent } from '../../office-attendance-import-dialog/office-attendance-import-dialog.component';

@Component({
  selector: 'app-office-attendance-admin',
  templateUrl: './office-attendance-admin.component.html',
  styleUrls: ['./office-attendance-admin.component.scss']
})
export class OfficeAttendanceAdminComponent implements OnInit {

  isSpinnerActive: boolean = true;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  attendances: any = [];
  filteredAttendances: any = [];
  selectedDate: any;
  page: number = 1;
  pageSize: number = 20; 
  totalPages: number = 0;
  searchQuery: string = ''; 
  selectedDepartment: string = ''; 
  sortConfig = { column: '', direction: 'asc' };
  departments = [
    { value: '', label: 'All Departments' },
    { value: 'API_MANAGEMENT_AND_INTEGRATION', label: 'API Management and Integration' },
    { value: 'DIGITAL_APPLICATION_DEVELOPMENT', label: 'Digital Application Development' },
    { value: 'DATA_MANAGEMENT', label: 'Data Management' },
    { value: 'CLIENT_SUCCESS', label: 'Client Success' },
    { value: 'DATA_SCIENCE_AND_AI', label: 'Data Science and AI' },
    { value: 'GEOGRAPHY', label: 'Geography' },
    { value: 'SUPPORT_FUNCTIONS', label: 'Support Functions' }
  ];

  constructor(private dialog: MatDialog,
              private officeAttendanceService: OfficeAttendanceService) {}

  ngOnInit(): void {
    this.setDate();
    this.getAllAttendanceRecords();
  }

  setDate() {
    const today = new Date();
    this.selectedDate = new Date(today);
    this.selectedDate.setDate(today.getDate() - 1);
    this.selectedDate = this.formatDate(this.selectedDate);
  }

  onDateChange(event: any): void {
    this.selectedDate = event.value;
    this.selectedDate = this.formatDate(this.selectedDate);
    this.page = 1; 
    this.getAllAttendanceRecords();
  }

  onSearchInput(value: string): void {
    this.searchQuery = value.trim();
    this.page = 1; 
    this.getAllAttendanceRecords();
  }

  onDepartmentChange(department: string): void {
    this.selectedDepartment = department;
    this.page = 1; 
    this.getAllAttendanceRecords();
  }

  onPageChange(newPage: number): void {
    this.page = newPage;
    this.getAllAttendanceRecords();
  }

  getAllAttendanceRecords() {
    this.isSpinnerActive = true;
    this.attendances = [];
    this.filteredAttendances = [];

    this.officeAttendanceService.getAllAttendanceRecords(
      this.selectedDate,
      this.page - 1,
      this.pageSize,
      this.searchQuery,
      this.selectedDepartment
    ).subscribe(res => {
      this.attendances = res.body?.content || [];
      this.filteredAttendances = [...this.attendances];
      this.totalPages = res.body?.totalPages || 0
      this.isSpinnerActive = false;
    }, () => {
      this.isSpinnerActive = false;
    });
    
  }

  addBulkAttendanceRecords() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '75%';
    dialogConfig.height = '75%';

    const dialogRef = this.dialog.open(OfficeAttendanceImportDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  formatMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  }


  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
