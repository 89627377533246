<mat-card class="main-card">
    <div class="row">
        <div class="col-md-6 ">
            <div class="main-header">
                <div class="svg-heading-div">
                    <img class="submodule-svg" src="assets/icons/dashboard-icons/wfo-icon.svg" />
                    <div class="head">Office Attendance</div>
                </div>
            </div>

            <!-- <div class="main-header" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="svg-heading-div" style="display: flex; align-items: center;">
                    <img class="submodule-svg" src="assets/icons/dashboard-icons/daily-attendance.svg" />
                    <div class="head">Office Attendance</div>
                </div>
                <img *ngIf="this.isEmployeeAdmin" class="img-class-bulk" style="cursor: pointer;" src="assets/svg/bulk-import.svg" />
            </div> -->


        </div>
        <div class="col-md-6 date-form">
            <form [formGroup]="attendanceForm">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Date<span class="contentRed"></span></mat-label>
                    <input matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy" formControlName="attendanceDate"
                        [matDatepickerFilter]="dateFilter" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker
                    [dateClass]="highlightedDateClass"></mat-datepicker>
                    <mat-error *ngIf="checkError('clientStartDate', 'required')">Required</mat-error>
                </mat-form-field>
            </form>
        </div>

    </div>

    <div class="row">
        <div *ngIf="!officeAttendanceData || officeAttendanceData.attendanceLogs.length == 0" class="col-md-6">
            <div class="table-fix-head-empty">
                <div class="heading-one">
                    <div class="no-data-image">
                        <img src="assets/icons/dashboard-icons/white-work-from-office-icon.png" />
                    </div>
                    <div class="no-data-text">
                        {{ getNoDataMessage() }}
                      </div>
                </div>
            </div>
        </div>

        <div *ngIf="officeAttendanceData && officeAttendanceData.attendanceLogs.length != 0" class="col-md-6">
            <div class="attendance-header">
                <h3 class="attendance-title">
                    Office Attendance for {{ selectedDate | date: 'dd/MM/yyyy' }}
                </h3>
            </div>
            <div class="table-fixed">
                <div class="table-header">
                    <div class="table-column">Time</div>
                    <div class="table-column">IN/OUT</div>
                </div>
                <div class="table-body">
                    <div *ngFor="let log of officeAttendanceData.attendanceLogs" class="table-row">
                        <div class="single-reportee-detail-container">
                            <div class="table-column">{{ log.eventTime }}</div>
                            <div class="table-column">
                                {{log.direction}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="table-fixed">
                <table class="summary-table">
                    <tr>
                        <th colspan="2" class="summary-title">Summary</th>
                    </tr>
                    <tr *ngIf="officeAttendanceData && officeAttendanceData.attendanceLogs.length != 0">
                        <td class="summary-label">Total hours completed on {{this.selectedDate | date:'dd/MM/yyyy'}} </td>
                        <td class="summary-value">{{ formatWorkedTime(officeAttendanceData.totalWorkedMinutes) }}</td>

                    </tr>
                    <tr *ngIf="officeAttendanceData.dateOfLastDataUploadedForTheMonth">
                        <td class="summary-label">Total hours worked till {{officeAttendanceData.dateOfLastDataUploadedForTheMonth | date:'dd/MM/yyyy'}} for the month (<strong>{{monthName}}</strong>)</td>
                        <td class="summary-value">{{ formatWorkedTime(officeAttendanceData.totalWorkedMinutesForMonth)
                            }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</mat-card>