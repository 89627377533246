<!-- Author: Mayur Madhekar -->
<div class="container-fluid ml-4">
    <div class="row back-btn">
      <figure (click)="closeDialog()">
        <img class="upload_img" src="assets/icons/back_arrow.png" alt="" />
        <label class="upload_text mt-4">Back</label>
      </figure>
    </div>
  
    <!-- Create Select File button and add readFile function to display the selected file name on screen -->
    <div id="parent">
      <label class="rectangle_lines center" (click)="fileInput.click()">{{
        this.uploadBtnLabel
      }}</label>
      <input
        type="file"
        id="my_file"
        [(ngModel)]="this.fileName"
        style="display: none"
        accept=".xlsx"
        (change)="readFile($event.target.files)"
        #fileInput
      />
  
      <!-- Display file name is file is selected, else display no file uploaded.
      Display upload button only when a file is selected and upon clicking it, 
      invoke uploadFile function 
      -->
      <div *ngIf="fileName" class="file_name center">
        {{ fileName }}
      </div>
      <div *ngIf="!fileName && (!isReuploading)" class="reupload-text center">
        <button  (click)="changeToReupload(fileInput)" class="reupload-text">
          {{ reuploadText }}
        </button>
      </div>

      <div *ngIf="isReuploading && (!fileName)" class="file_name center">
        {{"Select file to re-upload"}}
      </div>
      
      <button
        class="upload_btn"
        (click)="uploadFile()"
        type="submit"
        *ngIf="fileName && fileName !== 'Please wait...'"
      >
        <span class="upload_btn_name">Import Attendance</span>
      </button>
  
      <div>
        <label style="color: red" class="center" *ngIf="badFileFormat">
          {{this.errorMessage}}
        </label>
      </div>
    </div>
  </div>
  